.loader-container {
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    height: 70vh;
    /* Full viewport height */
}

.loader {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: block;
    position: relative;
    color: #000000;
    box-sizing: border-box;
    animation: animloader 1s linear infinite alternate;
}

@keyframes animloader {
    0% {
        box-shadow: -38px -6px, -14px 6px, 14px -6px;
    }

    33% {
        box-shadow: -38px 6px, -14px -6px, 14px 6px;
    }

    66% {
        box-shadow: -38px -6px, -14px 6px, 14px -6px;
    }

    100% {
        box-shadow: -38px 6px, -14px -6px, 14px 6px;
    }
}